export const getRecentSearch = (storageKey: string) => {
	const rawResult = localStorage.getItem(storageKey);
	const recentResult: string[] | null = !!rawResult ? JSON.parse(rawResult) : null;

	return recentResult;
};

export const setRecentSearch = (storageKey: string, value: string) => {
	const recentSearch = getRecentSearch(storageKey) || [];
	if (recentSearch.length === 5) {
		recentSearch.pop();
	}
	const newRecentSearch = [value, ...recentSearch.filter((x) => x !== value)];

	return localStorage.setItem(storageKey, JSON.stringify(newRecentSearch));
};

export const removeRecentSearch = (storageKey: string) => {
	localStorage.removeItem(storageKey);
};
