import { KeyboardEvent, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Form, Grid, Input } from 'antd';
import classNames from 'classnames';

import ImageC from 'src/components/Image';
import HeaderSearchDrawer from 'src/components/Layout/Header/HeaderSearch/HeaderSearchDrawer';
import HeaderSearchResults from 'src/components/Layout/Header/HeaderSearch/HeaderSearchResults';
import { HISTORY_SEARCH_KEYWORDS_ARTICLE } from 'src/constants/localStorage';
import { SEARCH_MIN_CHAR, SEARCH_MODULES } from 'src/constants/search';
import { useDefaultGlobalSearch } from 'src/hooks';
import { SearchModule } from 'src/interface';
import { isAlphabet } from 'src/utils/func';
import { navigateWithHardRefresh } from 'src/utils/func/navigation';
import { setRecentSearch } from 'src/utils/func/search';
import useComponentVisible from 'src/utils/hook/useComponentVisible';
import useDebounce from 'src/utils/hook/useDebounce';

// import KlikstoreSearchResult from './KlikstoreSearchResult';
import classes from './index.module.less';

const HeaderSearchC = (props: any) => {
	const r = useRouter();
	const location = useSelector((state: RootStateOrAny) => state.auth.userLocation);
	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

	const { xs, sm, md, lg } = Grid.useBreakpoint();
	const responsive = (xs || sm || md) && !lg;

	const [search, setSearch] = useState<string>('');
	const query = useDebounce(search, 500);

	const [module, setModule] = useState<SearchModule>(SEARCH_MODULES.ARTICLE);
	const [activeTab, setActiveTab] = useState<string>(SEARCH_MODULES.ARTICLE.tabs?.[0].name || '');

	const defaultGlobalRecords = useDefaultGlobalSearch({
		query,
		location,
		activeTab,
		enabled: module.name === 'article' && isAlphabet(query.substring(0, 3)),
	});
	const placeholder =
		/*isAskDoctorModule ||
		isMeetDoctorModule ||
		isHealthServiceModule ||
		isMedicalFacilityModule ||
		isKlikstoreModule*/
		module.placeholder;
	// : 'Cari Artikel';

	useEffect(() => {
		if (r.isReady) {
			/*if (isAskDoctorModule) {
				setActiveTab(SEARCH_MODULES.ASK_DOCTOR.tabs[0].name);
				setModule(SEARCH_MODULES.ASK_DOCTOR);
			} else if (isMeetDoctorModule) {
				setActiveTab(SEARCH_MODULES.MEET_DOCTOR.tabs[0].name);
				setModule(SEARCH_MODULES.MEET_DOCTOR);
			} else if (isHealthServiceModule) {
				setActiveTab(SEARCH_MODULES.HEALTH_SERVICE.tabs[0].name);
				setModule(SEARCH_MODULES.HEALTH_SERVICE);
			} else if (isMedicalFacilityModule) {
				setActiveTab(SEARCH_MODULES.MEDICAL_FACILITY.tabs[0].name);
				setModule(SEARCH_MODULES.MEDICAL_FACILITY);
			} else if (isKlikstoreModule) {
				setModule(SEARCH_MODULES.KLIKSTORE);
			} else {*/
			setActiveTab(SEARCH_MODULES.ARTICLE.tabs?.[0]?.name);
			setModule(SEARCH_MODULES.ARTICLE);
			// }
		}
	}, [r.isReady, r.pathname]);

	useEffect(() => {
		if (r.query.keyword) {
			setSearch(r.query.keyword as string);
		}
		setIsComponentVisible(false);
	}, [r]);

	const getDataRecords = () => {
		/*if (isAskDoctorModule) return askDoctorRecords;
		if (isMeetDoctorModule) return meetDoctorRecords;
		if (isHealthServiceModule) return healthServiceRecords;
		if (isMedicalFacilityModule) return medicalFacilityRecords;
		if (isKlikstoreModule) return klikstoreSearchRecords;*/
		return defaultGlobalRecords;
	};

	const handleChangeSearch = (e: any) => {
		const value = e.target.value;
		setSearch(value);
		setIsComponentVisible(value?.length >= SEARCH_MIN_CHAR.FOR_INFO);
	};

	const handleClickSearch = () => {
		// const showSearch = responsive ? true : query?.length >= SEARCH_MIN_CHAR.FOR_INFO;
		setIsComponentVisible(true);
	};

	const handleClickItemResult = () => {
		window?.dataLayer?.push?.({
			event: 'article_search',
			keyword: search,
		});
		setIsComponentVisible(false);
		//handle click article result
		setRecentSearch(HISTORY_SEARCH_KEYWORDS_ARTICLE, search);
	};
	const isNotAllowSearch = query.length < 3 || !isAlphabet(query.substring(0, 3));

	const onClickSearchIcon = () => {
		if (!isNotAllowSearch) navigateWithHardRefresh(`/artikel/search/${search}`);
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (!isNotAllowSearch && event.key === 'Enter') {
			navigateWithHardRefresh(`/artikel/search/${search}`);
		}
	};

	const renderSearchResult = () => {
		return (
			<div ref={ref}>
				<HeaderSearchResults
					isComponentVisible={isComponentVisible}
					query={query}
					module={module}
					records={getDataRecords()}
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					handleClickItemResult={handleClickItemResult}
					setSearch={setSearch}
				/>
			</div>
		);
	};

	return (
		<>
			<div className={classNames(classes.wrapper, { [classes.input]: !responsive })}>
				<Form autoComplete="off" className={classes.search} onClick={handleClickSearch}>
					<Input
						className={classNames({ [classes.focus]: isComponentVisible })}
						name="search"
						size="large"
						placeholder={placeholder}
						value={search}
						onClick={handleClickSearch}
						onChange={handleChangeSearch}
						onKeyDown={handleKeyDown}
						suffix={
							<ImageC
								alt="Icon Search"
								src="/icons/search.svg"
								height={responsive ? 16 : 24}
								width={responsive ? 16 : 24}
								className="cursor-pointer"
								onClick={onClickSearchIcon}
							/>
						}
					/>
				</Form>

				{responsive ? (
					<HeaderSearchDrawer
						isComponentVisible={isComponentVisible}
						setIsComponentVisible={setIsComponentVisible}
						placeholder={placeholder}
						search={search}
						query={query}
						setSearch={setSearch}
						module={module}
						records={getDataRecords()}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						onKlikNowProductSelect={props.onKlikNowProductSelect}
						handleClickItemResult={handleClickItemResult}
						handleKeyDown={handleKeyDown}
						onClickSearch={onClickSearchIcon}
					/>
				) : (
					renderSearchResult()
				)}
			</div>
		</>
	);
};

export default HeaderSearchC;
