import dynamic from 'next/dynamic';
import { useDetectAdBlock } from 'adblock-detect-react';

import ImageC from 'src/components/Image';
import { useIsEmptyContentGoogleAd } from 'src/hooks';
import { BannerItem, PromoProps } from 'src/interface';
import { TargetArrayType } from 'src/interface/AdSlot/index';

import classes from './Advertising.module.less';

type Props = {
	//remove later
	ads?: PromoProps[];
	adUnits: [string | undefined, string | undefined];
	sizes: any;
	adSource: string;
	adTarget: TargetArrayType;
	bannerAd?: BannerItem;
};

const ratioTmp = [
	{
		width: 350,
		height: 700,
	},
	{
		width: 350,
		height: 292,
	},
];

const GPT = dynamic(() => import('src/components/GPT'), { ssr: false });
const Advertising = (props: Props) => {
	const adBlockDetected = useDetectAdBlock();
	const { bannerAd, adSource, adTarget, adUnits, sizes } = props;
	const isEmptyAds = useIsEmptyContentGoogleAd();

	return (
		<>
			<div className={classes['google-ads']}>
				<GPT
					name={`gpt-ad-${adSource}-halfpage`}
					adUnit={adUnits[0]}
					size={sizes[0]}
					target={adTarget}
					className={classes.ads}
				/>
				<GPT
					name={`gpt-ad-${adSource}-mrec`}
					adUnit={adUnits[1]}
					size={sizes[1]}
					target={adTarget}
					className={classes.ads}
				/>
			</div>
			{bannerAd && bannerAd.status && (
				<a
					href={bannerAd.link}
					className={classes['internal-ads']}
					style={{
						display: isEmptyAds || adBlockDetected ? 'block' : 'none',
					}}
				>
					<div className={classes.ads}>
						<ImageC
							className={classes['ads-img']}
							alt={'Banner ad'}
							src={bannerAd.url_image}
							layout="responsive"
							objectFit="contain"
							width={ratioTmp[0].width}
							height={ratioTmp[0].height}
						/>
					</div>
				</a>
			)}
		</>
	);
};

export default Advertising;
