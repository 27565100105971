import { useMemo } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { Col, Grid, Row } from 'antd';

import ImageC from 'src/components/Image';
import { Title } from 'src/components/Layout/Typography';
import g from 'src/constants/global';
import { Articles, BannerItem, ICategoryByGroup, PromoProps } from 'src/interface';

import Advertising from './Advertising';
import CardArticle from './CardArticle';
import MainArticle from './MainArticle';
import TabMenu from './TabMenu';

import classes from './index.module.less';

type Props = {
	categoryTopic: ICategoryByGroup[];
	articles: Articles[];
	ads?: PromoProps[];
	activeKey: string;
	onChange: (e: any) => void;
	totalArticle?: number;
	isNoneSectionTitle?: boolean;
	showLoadmore?: boolean;
	showMainArticle?: boolean;
	color?: string;
	isSetHeight?: boolean;
	bannerAd?: BannerItem;
};

const GPT = dynamic(() => import('src/components/GPT'), { ssr: false });
const Article = (props: Props) => {
	const {
		activeKey,
		totalArticle,
		categoryTopic,
		showLoadmore,
		isNoneSectionTitle,
		articles,
		ads,
		onChange,
		bannerAd,
	} = props;
	const { xs, md, lg, xl } = Grid.useBreakpoint();
	const mdS = md ? 5 : 50;
	const lgS = lg ? 6 : mdS;
	const keyTopic = xl ? 7 : lgS;
	const showLoadmoreActive = showLoadmore ?? true;
	const dataCategoryTopic = categoryTopic || [];
	const activeKeySlug = dataCategoryTopic[Number(activeKey?.split('__')[0]) - 1]?.full_slug;
	const limit = ['pilihan', 'latest'].includes(activeKey?.split('__')[1]) ? totalArticle : 4;

	const categoryTopics = useMemo(() => {
		return [
			...dataCategoryTopic,
			{ name: 'Semua Kategori', full_slug: 'semua-kategori', slug: 'semua-kategori' },
		];
	}, [dataCategoryTopic]);

	return (
		<div className={`${classes['article-wrapper']} container`}>
			{!isNoneSectionTitle && (
				<>
					<Title
						level={2}
						text="Baca Artikel Kesehatan"
						className={`${classes.title} fs-16 fs-sm-20 fs-md-24 fw-700`}
					/>
					<Title
						level={3}
						text="Informasi kesehatan tepercaya hanya untukmu"
						className={`${classes.subtitle} fs-12 fs-sm-14 fs-md-16 fw-400 text-neutral-5`}
					/>
					{lg && (
						<div>
							<GPT
								name="gpt-ad-homepage-leaderboard"
								adUnit={g.AD.HOMEPAGE_LEADERBOARD_UNIT}
								size={g.AD.HOMEPAGE_LEADERBOARD_SIZE}
								target={[['channel', 'homepage']]}
								style={{ overflow: 'hidden', marginTop: -6, marginBottom: 32 }}
							/>
						</div>
					)}
					{xs && (
						<GPT
							name="gpt-ad-homepage-halfbanner"
							adUnit={g.AD.HOMEPAGE_MOBILE_HALFBANNER_UNIT}
							size={g.AD.HOMEPAGE_MOBILE_HALFBANNER_SIZE}
							target={[['channel', 'homepage']]}
							style={{ marginBottom: 22 }}
						/>
					)}
				</>
			)}

			<TabMenu
				activeKey={activeKey}
				onChange={onChange}
				categoryTopics={categoryTopics}
				keyTopic={keyTopic}
			/>

			<Row gutter={[24, 8]}>
				<Col xs={24} sm={24} md={24} lg={16} xl={16}>
					<MainArticle data={articles?.[0]} subCategory={activeKeySlug} />

					<div className={classes.section}>
						<CardArticle data={articles} subCategory={activeKeySlug} length={limit} />
					</div>

					{showLoadmoreActive && (
						<Link href={activeKeySlug || '/'} passHref className={classes['view-detail']}>
							<p>Lihat lebih banyak</p>
							<ImageC
								alt="arrow-right-short"
								src="/icons/arrow-right-short.svg"
								height={xs ? 24 : 32}
								width={xs ? 24 : 32}
							/>
						</Link>
					)}
				</Col>

				{lg && (
					<Col span={8}>
						<Advertising
							ads={ads}
							adSource="homepage"
							adTarget={[['channel', ['homepage']]]}
							adUnits={[g.AD.HOMEPAGE_HALFPAGE_UNIT, g.AD.HOMEPAGE_MEDIUMREC_UNIT]}
							sizes={[g.AD.HOMEPAGE_HALFPAGE_SIZE, g.AD.HOMEPAGE_MEDIUMREC_SIZE]}
							bannerAd={bannerAd}
						/>
					</Col>
				)}
			</Row>
		</div>
	);
};

export default Article;
