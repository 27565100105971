import { notification } from 'antd';
import queryString from 'query-string';

// import { setGeneralError } from 'src/components/ModalGeneralError';
import { isBrowser } from 'src/constants/environment';
import { platformEncoded } from 'src/constants/platform';
import g from 'src/constants/urls';

import { forceLogout } from './auth';
import AuthStorage, { idTokenStorage, refreshTokenStorage } from './auth-storage';
import deviceSize from './device-size';
import fetchWithAuthorization from './refresh-token';
import SettingsDebugStorage from './settings-debug-storage';

interface Options {
	method?: string;
	headers?: object;
	disableURLEncode?: boolean;
}

interface Property {
	url: string;
	options?: Options;
	payload?: any;
	notif?: boolean;
	ignoreDefault?: boolean;
	basePath?: string;
	apiHost?: string;
}

const isDebug = () => {
	return process.env.DEBUG_KEY ? process.env.DEBUG_KEY === SettingsDebugStorage.value : false;
};

const defaultFetchHeader = () => {
	let defaultHeader: any = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	};

	if (AuthStorage.token) {
		defaultHeader = {
			...defaultHeader,
			Authorization: `Bearer ${AuthStorage.token}`,
		};
	}

	if (isDebug()) {
		defaultHeader = {
			...defaultHeader,
			debug: process.env.DEBUG_KEY,
		};
	}

	return defaultHeader;
};

const getURI = (url: string, opts: any, payload: any) => {
	let uri = url;

	if (opts && opts.method === 'GET') {
		let encode = true;
		if (opts.disableURLEncode) {
			encode = false;
		}

		uri = queryString.stringifyUrl({ url: uri, query: payload }, { encode: encode });
	}

	return uri;
};

const getPayload = (opts: any, payload: any) => {
	if (opts.headers['Content-Type'] === 'multipart/form-data') {
		delete opts.headers['Content-Type'];

		const formData = new FormData();
		Object.entries(payload).forEach(([key, val]: any) => {
			if (val) {
				if (
					key === 'assetFiles' ||
					key === 'pdfFiles' ||
					key === 'images' ||
					key === 'newImages' ||
					key === 'newPdfFiles' ||
					key === 'newMediaFiles' ||
					key === 'deleteImages' ||
					key === 'deletePdfFiles' ||
					key === 'deleteMediaFiles'
				) {
					val.forEach((file: any) => {
						formData.append(key, file);
					});
				} else {
					formData.append(key, val);
				}
			}
		});

		opts.body = formData;
	} else {
		opts.body = JSON.stringify(payload);
	}

	return opts;
};

const replaceToken = (data: any) => {
	if (data?.data?.record?.token) {
		AuthStorage.value = { token: data.data.record.token };
		refreshTokenStorage.value = { refreshToken: data.data.record.refresh_token };
		idTokenStorage.value = { idToken: data.data.record.id_token };
	}
};

const disableFetchLog = () => {
	let disable = false;
	if (process.env.DISABLE_FETCH_API_LOG === 'true') {
		disable = true;
	}

	return disable;
};

const logRequest = (uri: string, opts: any, payload: any) => {
	if (isDebug() || (process.env.NODE_ENV === 'development' && !disableFetchLog())) {
		console.log('+++ Start Call Rest API +++');
		console.log('URI: ', uri);
		console.log('Options: ', opts);
		console.log('Payload: ', payload);
	}
};

const logResponse = (data: any) => {
	if (isDebug() || (process.env.NODE_ENV === 'development' && !disableFetchLog())) {
		console.log('Response: ', data);
		console.log('+++ End Call Rest API +++');
	}
};

const logError = (err: any) => {
	if (isDebug() || (process.env.NODE_ENV === 'development' && !disableFetchLog())) {
		console.log('--- Start Error Call Rest API ---');
		console.log('Response: ', err);
		console.log('--- End Error Call Rest API ---');
	}
};

const notifError = (err: any, notif = false) => {
	if (notif && isBrowser) {
		notification.error({
			message: 'Oops!',
			description: err?.meta?.message || err?.message || 'Terjadi kesalahan sistem.',
		});
	}
};

const handleError = (err: any, options?: Options, url?: string) => {
	if (err.status === 403 && url?.includes(`${g.API_KALCARE_URL}/quote`)) {
	} else if (err.status === 403 || err.status === 401) {
		forceLogout();
	}
};

const callFetch = async (
	{ url, options, payload = {}, notif = false }: Property,
	cb?: (...f: any) => void,
) => {
	try {
		let defaultOptions: any = {
			method: 'GET',
			headers: defaultFetchHeader(),
		};
		let opts: any = { ...defaultOptions, ...options };
		const uri = getURI(url, opts, payload);

		if (payload && Object.keys(payload).length > 0 && opts && opts.method !== 'GET') {
			opts = getPayload(opts, payload);
		}

		logRequest(uri, opts, payload);

		const response = await fetchWithAuthorization(uri, opts);

		if (response.ok && (response.status === 204 || response.statusText === 'No Content')) {
			if (cb) cb(null);
			return {};
		}

		let data: any;
		try {
			data = await response.json().then((res) => ({ ...res, status: response.status }));
		} catch (errr: any) {
			data = {};
		}

		logResponse(data);

		if (response.status !== 200 || response.status >= 400 || !response.ok) {
			notifError(data, notif);
			handleError(data, opts, url);
			throw data;
		}

		replaceToken(data);

		if (cb) cb(null, data || {});
		return data || {};
	} catch (err: any) {
		logError(err);
		handleError(err, options, url);

		if (cb) cb(err);
		throw err;
	}
};

// Fetch API Publishing
export const fetchApiPublishing = (
	{ url, options, payload = {}, notif = false }: Property,
	cb?: (...f: any) => void,
) => {
	const apiUrl = `${g.API_URL}/publishing`;
	const fetchUrl = `${apiUrl}${url}`;
	const optHeader = options?.headers;

	if (options?.headers) {
		delete options.headers;
	}

	const fetchOptions = {
		headers: {
			...defaultFetchHeader(),
			...optHeader,
			'x-api-platform': platformEncoded,
		},
		...options,
	};

	return callFetch(
		{
			url: fetchUrl,
			options: fetchOptions,
			payload,
			notif,
		},
		cb,
	);
};

// Fetch API V3 Publishing
export const fetchApiV3Publishing = (
	{ url, options, payload = {}, notif = false }: Property,
	cb?: (...f: any) => void,
) => {
	const apiUrl = `${g.APIV3_PB_URL}`;
	const fetchUrl = `${apiUrl}${url}`;
	const optHeader = options?.headers;

	if (options?.headers) {
		delete options.headers;
	}

	const fetchOptions = {
		headers: {
			...defaultFetchHeader(),
			...optHeader,
		},
		...options,
	};

	return callFetch(
		{
			url: fetchUrl,
			options: fetchOptions,
			payload,
			notif,
		},
		cb,
	);
};

// Fetch API Janji Medis
export const fetchApiJM = (
	{ url, options, payload = {}, notif = false }: Property,
	cb?: (...f: any) => void,
) => {
	const apiUrl = `${g.API_URL}/jm`;
	const fetchUrl = `${apiUrl}${url}`;
	const optHeader = options?.headers;

	if (options?.headers) {
		delete options.headers;
	}

	const fetchOptions = {
		headers: {
			...defaultFetchHeader(),
			...optHeader,
		},
		...options,
	};

	return callFetch(
		{
			url: fetchUrl,
			options: fetchOptions,
			payload,
			notif,
		},
		cb,
	);
};

// Fetch API Consultation
export const fetchApiConsultation = (
	{ url, options, payload = {}, notif = false }: Property,
	cb?: (...f: any) => void,
) => {
	const apiUrl = `${g.API_URL_V3}/consultation`;
	const fetchUrl = `${apiUrl}${url}`;
	const optHeader = options?.headers;

	if (options?.headers) {
		delete options.headers;
	}

	const fetchOptions = {
		headers: {
			...defaultFetchHeader(),
			...optHeader,
			'x-api-platform': platformEncoded,
		},
		...options,
	};

	return callFetch(
		{
			url: fetchUrl,
			options: fetchOptions,
			payload,
			notif,
		},
		cb,
	);
};

// Fetch API V3 Healthcare
export const fetchApiV3Healthcare = (
	{ url, options, payload = {}, notif = false }: Property,
	cb?: (...f: any) => void,
) => {
	const fetchUrl = `${g.APIV3_HC_URL}${url}`;
	const optHeader = options?.headers;

	if (options?.headers) {
		delete options.headers;
	}

	const fetchOptions = {
		headers: {
			...defaultFetchHeader(),
			...optHeader,
		},
		...options,
	};

	return callFetch(
		{
			url: fetchUrl,
			options: fetchOptions,
			payload,
			notif,
		},
		cb,
	);
};

// Fetch API Payment
export const fetchApiPayment = (
	{ url, options, payload = {}, notif = false }: Property,
	cb?: (...f: any) => void,
) => {
	const apiUrl = `${g.API_URL}/payment`;
	const fetchUrl = `${apiUrl}${url}`;
	const optHeader = options?.headers;

	const defaultPayload = {
		...payload,
		app_version: g.API_PAYMENT_VERSION,
		platform: g.API_PAYMENT_PLATFORM,
	};

	if (options?.headers) {
		delete options.headers;
	}

	const fetchOptions = {
		headers: {
			...defaultFetchHeader(),
			...optHeader,
		},
		...options,
	};

	return callFetch(
		{
			url: fetchUrl,
			options: fetchOptions,
			payload: defaultPayload,
			notif,
		},
		cb,
	);
};
// Fetch API Promotion
export const fetchApiPromotion = (
	{ url, options, payload = {}, notif = false }: Property,
	cb?: (...f: any) => void,
) => {
	const apiUrl = `${g.API_URL}/promotion`;
	const fetchUrl = `${apiUrl}${url}`;
	const optHeader = options?.headers;

	if (options?.headers) {
		delete options.headers;
	}

	const fetchOptions = {
		headers: {
			...defaultFetchHeader(),
			...optHeader,
		},
		...options,
	};

	return callFetch(
		{
			url: fetchUrl,
			options: fetchOptions,
			payload,
			notif,
		},
		cb,
	);
};

// Fetch API Prescription
export const fetchApiPrescription = (
	{ url, options, payload = {}, notif = false }: Property,
	cb?: (...f: any) => void,
) => {
	const apiUrl = `${g.API_URL}/rx`;
	const fetchUrl = `${apiUrl}${url}`;
	const optHeader = options?.headers;

	if (options?.headers) {
		delete options.headers;
	}

	const fetchOptions = {
		headers: {
			...defaultFetchHeader(),
			...optHeader,
		},
		...options,
	};

	return callFetch(
		{
			url: fetchUrl,
			options: fetchOptions,
			payload,
			notif,
		},
		cb,
	);
};

// Fetch API Partner
export const fetchApiPartner = (
	{ url, options, payload = {}, notif = false }: Property,
	cb?: (...f: any) => void,
) => {
	const apiUrl = `${g.API_PARTNER_URL}`;
	const fetchUrl = `${apiUrl}${url}`;
	const optHeader = options?.headers;

	if (options?.headers) {
		delete options.headers;
	}

	const fetchOptions = {
		headers: {
			...defaultFetchHeader(),
			...optHeader,
		},
		...options,
	};

	return callFetch(
		{
			url: fetchUrl,
			options: fetchOptions,
			payload,
			notif,
		},
		cb,
	);
};

// Fetch API V3 Rating
export const fetchApiV3Rating = (
	{ url, options, payload = {}, notif = false }: Property,
	cb?: (...f: any) => void,
) => {
	const apiUrl = `${g.APIV3_RATING_URL}`;
	const fetchUrl = `${apiUrl}${url}`;
	const optHeader = options?.headers;

	if (options?.headers) {
		delete options.headers;
	}

	const fetchOptions = {
		headers: {
			...defaultFetchHeader(),
			...optHeader,
		},
		...options,
	};

	return callFetch(
		{
			url: fetchUrl,
			options: fetchOptions,
			payload,
			notif,
		},
		cb,
	);
};

// Fetch API Typesense
export const fetchApiTypesense = (
	{ url, options, payload = {}, notif = false }: Property,
	cb?: (...f: any) => void,
) => {
	const apiUrl = `${g.TYPESENSE_URL}`;
	const fetchUrl = `${apiUrl}${url}`;
	const optHeader = options?.headers;

	if (options?.headers) {
		delete options.headers;
	}

	const fetchOptions = {
		headers: {
			...defaultFetchHeader(),
			...optHeader,
		},
		...options,
	};

	return callFetch(
		{
			url: fetchUrl,
			options: fetchOptions,
			payload,
			notif,
		},
		cb,
	);
};

// Fetch API V3 User
export const fetchApiV3User = (
	{ url, options, payload = {}, notif = false }: Property,
	cb?: (...f: any) => void,
) => {
	const apiUrl = `${g.APIV3_USER_URL}`;
	const fetchUrl = `${apiUrl}${url}`;
	const optHeader = options?.headers;

	if (options?.headers) {
		delete options.headers;
	}

	const fetchOptions = {
		headers: {
			...defaultFetchHeader(),
			...optHeader,
			'x-api-platform': platformEncoded,
		},
		...options,
	};

	return callFetch(
		{
			url: fetchUrl,
			options: fetchOptions,
			payload,
			notif,
		},
		cb,
	);
};

// Fetch API Marketplace
export const fetchApiMarketplace = (
	{ url, options, payload = {}, notif = false }: Property,
	cb?: (...f: any) => void,
) => {
	const device = deviceSize();

	const apiUrl = `${g.API_KALCARE_URL}`;
	const fetchUrl = `${apiUrl}${url}`;
	const optHeader = options?.headers;

	if (options?.headers) {
		delete options.headers;
	}

	const fetchOptions = {
		headers: {
			...defaultFetchHeader(),
			...optHeader,
			'x-user-agent': device === 'desktop' ? 1 : 2,
		},
		...options,
	};

	return callFetch(
		{
			url: fetchUrl,
			options: fetchOptions,
			payload,
			notif,
		},
		cb,
	);
};

export const fetchApiV3Insurance = (
	{ url, options, payload = {}, notif = false }: Property,
	cb?: (...f: any) => void,
	baseApiUrl?: string,
) => {
	const apiUrl = `${baseApiUrl || g.API_INSURANCE_URL}`;
	const fetchUrl = `${apiUrl}${url}`;
	const optHeader = options?.headers;

	if (options?.headers) {
		delete options.headers;
	}

	const fetchOptions = {
		headers: {
			...defaultFetchHeader(),
			...optHeader,
		},
		...options,
	};

	return callFetch(
		{
			url: fetchUrl,
			options: fetchOptions,
			payload,
			notif,
		},
		cb,
	);
};
