import metadata from 'src/constants/metadata';

export const reviewerName = (article: any) => {
	const type = article?.categories?.length > 0 ? article?.categories[0]?.type : null;
	const reviewer = article?.reviewer_name;
	if (reviewer) {
		return reviewer;
	} else if (type === 'general') {
		return 'Tim Medis KlikDokter';
	} else if (type === 'doctor') {
		return 'Tim Apoteker KlikDokter';
	} else {
		return metadata.APP_NAME;
	}
};

export const authorName = (article: any) => {
	const author = article?.author_name;
	const type = article?.categories?.length > 0 ? article?.categories[0]?.type : null;
	if (author) {
		return author;
	} else if (type === 'obat') {
		return 'KlikDokter';
	} else if (type === 'penyakit') {
		return 'Tim Medis KlikDokter';
	} else if (type === 'general') {
		return 'KlikDokter';
	} else {
		return metadata.APP_NAME;
	}
};

export const isAlphabet = (text: string) => {
	return /^[A-Za-z]+$/.test(text);
};
