import { useState } from 'react';
import Link from 'next/link';
import { Button, Col, Divider, Grid, Layout, Row } from 'antd';
import classNames from 'classnames';

// import ModalKlikNow from 'src/components/KlikStore/KlikNow/ModalKlikNow';
import LogoC from 'src/components/Layout/Header/Logo';
import { MainDrawerMenuC } from 'src/components/Layout/Menu';
import UserMenuC from 'src/components/Layout/Menu/UserMenu';
import { PageHeaderProps } from 'src/interface';
import AuthStorage from 'src/utils/auth-storage';
import deviceSize from 'src/utils/device-size';

import HeaderSearchC from './HeaderSearch';

import classes from './index.module.less';

interface HeaderPropsC {
	isWhiteBackground: boolean;
	pageHeaderContent: PageHeaderProps;
	isDrawerOnly?: boolean;
}

const HeaderC = (props: HeaderPropsC) => {
	const { xs, sm, md, lg } = Grid.useBreakpoint();
	const responsive = (xs || sm || md) && !lg;

	const buttonSize = deviceSize() === 'desktop' ? 'large' : 'middle';
	const [isVisibleComponent, setIsVisibleComponent] = useState<string>('');

	return (
		<>
			<Layout.Header className={classNames(classes.header, 'h-25')}>
				<div className={classNames('container', classes['header-wrap'])}>
					<Row
						gutter={responsive ? 8 : 32}
						wrap={false}
						style={{ padding: responsive ? '10px 0 10px 0' : '0' }}
					>
						<Col flex="none" style={{ alignItems: 'center', display: 'flex' }}>
							<div className="d-flex align-items-center">
								<MainDrawerMenuC
									placement="left"
									visibleComponent={isVisibleComponent}
									setVisibleComponent={setIsVisibleComponent}
								/>

								<div className={classes['mobile-logo-click']}>
									<div className="ml-0 ml-sm-1 ml-md-3">
										<LogoC ClickImage={() => setIsVisibleComponent('')} />
									</div>
								</div>
							</div>
						</Col>
						<Col
							className={classNames('pr-0')}
							flex="1"
							style={{
								alignSelf: 'center',
							}}
						>
							{!props.isDrawerOnly ? <HeaderSearchC /> : null}
						</Col>

						<Col
							flex="none"
							className="text-right align-self-center"
							style={{ marginLeft: 'auto' }}
						>
							{!props.isDrawerOnly ? (
								<>
									{AuthStorage.loggedIn ? (
										<UserMenuC
											setVisibleComponent={setIsVisibleComponent}
											visibleComponent={isVisibleComponent}
										/>
									) : (
										<Row align="middle" justify="end">
											<Col>
												<Button
													type="primary"
													size={buttonSize}
													className={classNames(classes.button, 'd-flex align-items-center')}
												>
													<Link href="/signin" className="fs-14 fs-lg-16 mx-sm-2 mx-md-3">
														Masuk
													</Link>
												</Button>
											</Col>
										</Row>
									)}
								</>
							) : null}
						</Col>
					</Row>
				</div>
			</Layout.Header>
			{props.isWhiteBackground && !props.pageHeaderContent.title && (
				<Divider className={classes.divider} />
			)}
		</>
	);
};

export default HeaderC;
