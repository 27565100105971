import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useDetectAdBlock } from 'adblock-detect-react';
import { Grid, Layout } from 'antd';
import classNames from 'classnames';

import FooterC from 'src/components/Layout/Footer';
import HeaderC from 'src/components/Layout/Header';
import PageHeader from 'src/components/Layout/PageHeader';
import g from 'src/constants/global';
import { useIsEmptyContentGoogleAd } from 'src/hooks';
import { PageHeaderProps } from 'src/interface';
import deviceSizeC from 'src/utils/device-size-client';
import { isEmpty } from 'src/utils/func';
import { removeQueryParams } from 'src/utils/func/format-query';
import layoutMobile from 'src/utils/layout-mobile';

import classes from './index.module.less';

const GPT = dynamic(() => import('src/components/GPT'), { ssr: false });
const DynamicHeaderC = dynamic(() => import('src/components/Layout/Header'), { ssr: false });
const DynamicFooterC = dynamic(() => import('src/components/Layout/Footer'), { ssr: false });
const BreadcrumbJsonLdC = dynamic(() => import('src/components/Layout/JsonLd/Breadcrumb'));
const OrganizationJsonLdC = dynamic(() => import('src/components/Layout/JsonLd/Organization'));

const headerCD = (
	props: any,
	r: any,
	isWhiteBackground: boolean,
	pageHeaderContent: PageHeaderProps,
	isDrawerOnly?: boolean,
) => {
	if (props.isErrorPage) {
		return null;
	}
	return r.pathname === '/' ? (
		<HeaderC isWhiteBackground={isWhiteBackground} pageHeaderContent={pageHeaderContent} />
	) : (
		<DynamicHeaderC
			isWhiteBackground={isWhiteBackground}
			pageHeaderContent={pageHeaderContent}
			isDrawerOnly={isDrawerOnly}
		/>
	);
};

const footerCD = (props: any, r: any, isMiniFooter: boolean, isNoneFooter: boolean) => {
	if (props.isErrorPage) {
		return null;
	}
	return r.pathname === '/' ? (
		<FooterC isMiniFooter={isMiniFooter} isNoneFooter={isNoneFooter} />
	) : (
		<DynamicFooterC isMiniFooter={isMiniFooter} isNoneFooter={isNoneFooter} />
	);
};

const MainLayout = (props: any) => {
	const r = useRouter();
	const lm = layoutMobile(r);
	const breakpoint = Grid.useBreakpoint();

	const adBlockDetected = useDetectAdBlock();
	const isEmptyContentGoogleAd = useIsEmptyContentGoogleAd();
	const { pageHeaderContent, isWhiteBackground, isMiniFooter, isNoneFooter, isDrawerOnly } = props;
	const bodyWhite = pageHeaderContent?.title
		? classes['white-layout-body-card']
		: classes['white-layout-body'];
	const pathArr = removeQueryParams(r.asPath)?.split('/');
	const path1 = pathArr[1] && pathArr[1].split('#')[0];
	const path2 = pathArr[2];
	const articlePaths = [
		'/gaya-hidup',
		'/ibu-anak',
		'/info-sehat',
		'/obat',
		'/penyakit',
		'/psikologi',
	];
	const showAd = articlePaths.includes(`/${path1}`) && (!adBlockDetected || lm);
	const isDetailPage = !isEmpty(props.detail);

	return (
		<>
			<Layout className={classes.container}>
				{!lm && headerCD(props, r, isWhiteBackground, pageHeaderContent, isDrawerOnly)}
				{props?.jsonLd?.organization && <OrganizationJsonLdC />}
				{props?.jsonLd?.breadcrumbs && pageHeaderContent?.breadcrumbs && (
					<BreadcrumbJsonLdC pageHeaderContent={pageHeaderContent} />
				)}

				<Layout
					className={classNames({
						'bg-white': isWhiteBackground,
						[classes['layout-body']]: !isWhiteBackground,
					})}
				>
					<div
						className={isWhiteBackground ? bodyWhite : ''}
						style={
							showAd && deviceSizeC(breakpoint) === 'mobile' && !isEmptyContentGoogleAd
								? { paddingBottom: 49 }
								: {}
						}
					>
						{!lm && pageHeaderContent && <PageHeader {...pageHeaderContent} />}
						<Layout.Content>{props.children}</Layout.Content>
						{!lm && footerCD(props, r, isMiniFooter, isNoneFooter)}
						{showAd && deviceSizeC(breakpoint) === 'mobile' && (
							<GPT
								name="gpt-ad-article-mobile-banner"
								adUnit={
									isDetailPage
										? g.AD.DTL_ARTICLE_MOBILE_BANNER_UNIT
										: g.AD.CTG_ARTICLE_MOBILE_BANNER_UNIT
								}
								size={
									isDetailPage
										? g.AD.DTL_ARTICLE_MOBILE_BANNER_SIZE
										: g.AD.CTG_ARTICLE_MOBILE_BANNER_SIZE
								}
								target={[['channel', [path1, path2]]]}
								className={classes['mobile-banner']}
							/>
						)}
					</div>
				</Layout>
			</Layout>
		</>
	);
};

export default MainLayout;
