export const CATEGORY_TOPIC_REGISTER = {
	service: 'category-topic-regist-form',
};

export const USER = {
	service: 'users',
	model: {
		address: 'address',
		check: 'check',
		checkPin: 'pin/check',
		checkRelation: 'check-relation',
		logged: 'logged',
		login: 'login',
		reactive: 'reactive',
		register: 'register',
		favorite: 'favorite',
		forgotPin: 'pin/forgot',
		changePin: 'pin/change',
		setPin: 'pin/set-pin',
		profile: 'profile',
		listRelationship: 'list-relationship',
		updateRelationship: 'update-relationship',
		deleteRelationship: 'delete-relationship',
		logout: 'logout',
		updateImage: 'update-image',
		resendRelationshipVerification: 'resend-relationship-verification',
		verifyRelationship: 'verify-relationship',
		listTopic: 'category-topic',
		referralCode: 'referral-code',
		tokenFCM: 'device',
		membershipHistoryPoint: 'membership-history-point',
		membershipSource: 'membership-source',
		updateProfileIdentity: 'update-profile-identity',
		likes: 'likes',
	},
};

export const PHONE = {
	service: 'phone',
	model: {
		checkNumber: 'check',
		changeNumber: 'change',
	},
};

export const LIST_RELATIONSHIP = {
	service: 'list-relationship',
};

export const MENU = {
	service: 'menu/v2',
};

export const OTP = {
	service: 'otp',
	model: {
		validate: 'validate',
		validUser: 'user-valid',
	},
};

export const FOOTER_MENU = {
	service: 'footer-menu',
};

export const HOMEPAGE = {
	service: 'homepage',
	model: {
		banner: 'banner',
	},
};

export const DOWNLOAD = {
	service: 'download-section',
};

export const CATEGORY_TOPIC_ARTICLE = {
	service: 'category-topic-home',
};

export const ARTICLES = {
	service: 'articles',
	model: {
		category: 'getbycategory',
		include: 'include',
		limit: 'limit',
		page: 'page',
	},
};

export const MEDICAL_HISTORY = {
	service: 'medical-history',
	model: {
		family: 'family-member',
	},
};

export const UPLOAD = {
	service: 'upload',
};

export const UPLOAD_IMAGES = {
	service: 'upload-images',
};

export const PRESIGN = {
	service: 'presign',
};

export const CONTACTS_TOPIC = {
	service: '/contact-us-topic',
};

export const CONTACTS_OFFICE = {
	service: '/contact-us-office',
};

export const ABOUT = {
	service: 'about-us',
};

export const POLICY = {
	service: 'policy',
};

export const PROFILE = {
	service: 'static-info',
	model: {
		category: 'category',
		type: 'type',
		listMembership: 'list-membership',
		membershipPoint: 'membership-point',
		pointRuleInformation: 'info-klikpoint',
		tncIdCard: 'tnc-id-card',
	},
};

export const HEALTH_TYPE = {
	SERVICE: 'service',
	HOSPITAL: 'hospital',
	DOCTOR: 'doctor',
	SPECIALIST: 'specialist',
	MEDICAL_FACILITES: 'medical_facilites',
};

export const HEALTHCARE_TOOLS = {
	service: 'public/healthcare-tools',
	servicePrivate: 'healthcare-tools',
	model: {
		content: 'contents',
		calculate_risk: 'calculate-risk',
		calculate_bmi: 'calculate-bmi',
		calculate_fertility: 'calculate-fertility',
		tanya_obatmu: 'tanya-obatmu',
		unique_link: 'unique_link',
	},
};

export const PAYMENT = {
	service: 'payment',
	model: {
		key: 'get_key',
		history: 'history',
		transactionList: 'transaction/list',
		transactionDetail: 'transaction/detail',
		receiptDetail: 'kwitansi/detail',
		journey: 'journey',
		walletList: 'wallet/list',
		walletInfo: 'wallet/info',
		walletDefault: 'wallet/default',
		walletSetDefault: 'wallet/set-default',
		walletLink: 'wallet/link',
		walletLinkVerify: 'wallet/link/verify',
		walletUnlink: 'wallet/unlink',
		walletStepTopUp: 'topup',
		invoice: 'invoice',
		summary: 'summary',
		dropdown: 'dropdown',
		method: 'method',
		submit: 'submit',
		voucherApply: 'voucher/apply',
		voucherDelete: 'voucher/delete',
		summaryDetail: 'summary/detail',
		setShipmentMethod: 'courier',
		check: 'check',
		waitingCount: 'waiting/count',
		waiting: 'waiting',
		admission: 'admission',
		aso: 'aso',
		outstanding: 'outstanding',
		transactionCheck: 'transaction/check',
	},
};

export const DOCTOR = {
	service: 'doctors',
	model: {
		schedules: 'schedules',
		favorite: 'favorite',
		information: 'info',
		price: 'price',
		availability: 'availability',
		clinics: 'clinics',
		recommendations: 'recommendations',
		reviews: 'reviews',
		tabCalendar: 'tab-calendar',
		searchSuggestions: 'search-suggestions',
		listShareSocmed: 'list-share-social-media',
	},
};
export const AUTHOR = {
	service: 'author',
	model: {
		slug: 'slug',
		excludeSlug: 'exclude_slug',
		articles: 'articles',
	},
};

export const PRODUCT = {
	service: 'public/products',
	model: {
		product: 'products',
	},
};

export const PAYMENT_V3 = {
	service: '',
	model: {
		cart: 'cart',
		review: 'review',
	},
};

export const STORE = {
	service: 'public/stores',
	model: {
		store: 'stores',
	},
};

export const CHAT = {
	service: 'chat',
	model: {
		unread: 'rooms-unread',
		unrated: 'unrated',
		order: 'order',
		reset: 'post-cancel',
	},
};

export const HOSPITAL = {
	service: 'hospitals',
	model: {
		categories: 'categories',
	},
};

export const VIDEO_CALL = {
	service: 'video-call',
	model: {
		filter: 'filter',
		patientJoin: 'patient/join',
		patientFinish: 'patient/finish',
		cancel: 'cancel',
		order: 'order',
	},
};

export const MEET = {
	model: {
		order: 'appointment-face-to-face',
	},
};

export const COMMON = {
	service: 'common',
	model: {
		filter: 'filter',
		kota: 'kota',
		socialMedia: 'list-share-social-media',
		cancelReason: 'cancelation-reason',
	},
};

export const HELP = {
	model: {
		report: 'send-report-problem',
		category: 'get-category-problem',
	},
};

export const VOUCHER = {
	service: {
		voucher: 'voucher',
		vouchers: 'vouchers',
		promo_homepage: 'promo-homepage',
	},
};

export const POINT_REWARD = {
	service: 'api/voucher',
	model: {
		vouchers: 'public',
		myVouchers: 'list_detail',
		redeemVouchers: 'tukar-poin',
		voucherDetail: 'detail',
		partner: 'partner',
		partnerUse: 'use',
		partnerReverse: 'reverse',
	},
};

export const ARTICLE_BY_GROUP = {
	service: '/public/kd/articles',
	slug: '/public/kd/articles/slug',
	detail: '/public/kd/articles/detail',
	byCategory: '/public/kd/articles/category',
	byCategoryPrivate: '/kd/articles/category',
	byType: '/kd',
	groups: '/public/kd/article-groups',
	model: {
		obat: 'obat',
		penyakit: 'penyakit',
		mostSearch: 'ar-obat-most-search',
		globalSearch: 'global-search',
	},
};

export const CATEGORY = {
	service: '/public/kd/categories',
	servicePrivate: '/kd/categories',
	slug: '/public/kd/categories/slug',
	slugObat: '/public/kd/articles/',
	all: '/public/kd/categories/all',
	groups: '/kd/category-groups',
	model: {
		obat: 'obat',
		penyakit: 'penyakit',
	},
};

export const PRESCRIPTION = {
	service: '/prescription',
	model: {
		orderConfirm: 'orderconfirm',
		orderChat: 'orderchat',
		orderCancel: 'order_cancel',
		patient: 'patient',
		pharmacy: 'pharmacy',
	},
};

export const NOTIFICATION_CENTER = {
	service: 'notifications-center',
	model: {
		trasaction: 'transaction',
		promo: 'promo',
		info: 'info',
		update: 'update',
		unread: 'unread',
	},
};

export const WAITING_TRANSACTION = {
	service: 'waiting',
	model: {
		count: 'count',
	},
};

export const WEARABLE_DEVICE = {
	service: 'wearable-device',
	model: {
		connect: 'connect',
		disconnect: 'disconnect',
		numberConnected: 'number-connected',
		shareWearable: 'share-wearable',
	},
	device: {
		omron: 'omron',
	},
};

export const RATING = {
	service: 'public/rating-submissions',
	model: {
		service: 'layanan',
		ratingSubmissions: 'rating-submissions',
		uploadImage: 'upload/images',
		ratingSubmissionsById: 'public/rating-submissions-by-id',
		ratingProductSummary: 'public/ratings-summary/detail/product',
		overallProductRating: 'public/ratings-summary/product',
	},
};

export const TYPESENSE = {
	service: 'multi_search',
	model: {
		categoryServices: 'kd_category_services',
		categorySpecialities: 'kd_category_specialities',
		medicalFacilities: 'kd_medical_facilities',
		medicalFacilityDoctors: 'kd_medical_facility_doctors',
		medicalFacilityServices: 'kd_medical_facility_services',
		klikstoreProduct: 'store-product',
		klikstoreBrand: 'brand',
		klikstoreMerchant: 'store',
		klikstorePopularSearch: 'popular-search',
	},
};

export const MARKETPLACE_CONTENT_SITE = {
	service: 'content-site',
	model: {
		landingpPageBanner: 'section_one',
		landingpPageCategories: 'section_six',
	},
};

export const MARKETPLACE_FLOORS = {
	service: 'floors',
};

export const MARKETPLACE_FLOORS_SECTION = {
	service: 'floors-section',
	model: {
		landingPage: 'landing_page',
	},
};

export const MARKETPLACE_BANNER = {
	service: 'banner',
	model: {
		brandPage: 'brand_page',
	},
};

export const MARKETPLACE_CART = {
	service: 'cart',
	model: {
		usePromo: 'promo/use',
		removePromo: 'promo/remove',
		checkout: 'checkout',
	},
};

export const MARKETPLACE_CHECKOUT = {
	service: '',
	model: {
		summary: 'summary',
		voucherApply: 'voucher/apply',
		voucherDelete: 'voucher/delete',
		paymentMethodList: 'method',
		submit: 'submit',
		transactionDetail: 'transaction/detail',
		addressSet: 'user/address',
		shipmentSet: 'shipment/set',
	},
};

export const INSTANT_CHATS = {
	service: 'instant-chats',
	model: {
		schedulesActive: 'schedules/active',
		schedules: 'schedules',
		order: 'order',
		orders: 'orders',
		requeue: 'requeue',
		cancel: 'cancel',
	},
};

export const INSURANCE = {
	service: 'insurances',
};

export const VERIFY_INSURANCE = {
	service: 'verify',
};
export const USER_INSURANCE = {
	service: 'user-insurances',
	model: {
		benefits: 'benefits',
		status: 'status',
		claims: 'claims',
		letterConfirmations: 'letter-of-confirmations',
	},
};

export const BENEFIT = {
	service: 'benefit',
	model: {
		history: 'history',
	},
};

export const INSURANCE_TRANSACTION = {
	service: 'insurance-transactions',
};

export const PUBLISHING_KD = {
	service: 'public/kd',
	model: {
		categoryInsurance: 'category-insurance',
		productInsurance: 'product-insurance',
		closeFeature: 'close-feature/module',
		ads: 'ads',
	},
};
