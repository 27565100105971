import React, { useState } from 'react';
import { Typography } from 'antd';
import classNames from 'classnames';

import ImageC from 'src/components/Image';
import { HISTORY_SEARCH_KEYWORDS_ARTICLE } from 'src/constants/localStorage';
import { getRecentSearch, removeRecentSearch } from 'src/utils/func/search';

import classes from './HeaderSearchHistory.module.less';

type HeaderSearchHistoryProps = {
	onClickItemHistory: (word: string) => void;
};

const initialList = () => getRecentSearch(HISTORY_SEARCH_KEYWORDS_ARTICLE);

const HeaderSearchHistory = ({ onClickItemHistory }: HeaderSearchHistoryProps) => {
	const [list, setList] = useState(initialList);

	if (!list) {
		return null;
	}

	return (
		<div>
			<div className="px-2 px-md-3 mb-1 d-flex justify-content-between">
				<Typography.Text className="fs-14 fs-xs-12 text-neutral-7 fw-500">
					Terakhir dicari
				</Typography.Text>
				<Typography.Text
					className="fs-14 fs-xs-12 text-neutral-7 fw-500 cursor-pointer"
					onClick={() => {
						removeRecentSearch(HISTORY_SEARCH_KEYWORDS_ARTICLE);
						setList(null);
					}}
				>
					Hapus
				</Typography.Text>
			</div>
			<ul className={classNames('d-flex flex-column', classes.list)}>
				{list.map((l) => (
					<li
						key={l}
						className="px-2 py-0 py-md-1 px-md-3 fs-14 fs-xs-12 text-neutral-7 cursor-pointer"
						onClick={() => {
							onClickItemHistory(l);
						}}
					>
						<div className="d-flex align-items-center gapx-2">
							<ImageC
								className="rounded-lg"
								height="14"
								width="14"
								src="/icons/clock.svg"
								objectFit="cover"
							/>
							<p className="m-0 fw-600 text-neutral-5">{l}</p>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
};

export default HeaderSearchHistory;
