import { KeyboardEvent, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { Button, Col, Drawer, Form, Input, Row } from 'antd';

import ImageC from 'src/components/Image';
import { SEARCH_MIN_CHAR } from 'src/constants/search';
import { IKlikStoreMasterProduct, SearchModule } from 'src/interface';

import HeaderSearchResults from './HeaderSearchResults';

// import KlikstoreSearchResult from './KlikstoreSearchResult';
import classes from './HeaderSearchDrawer.module.less';

type Props = {
	isComponentVisible: boolean;
	setIsComponentVisible: (v: boolean) => void;
	placeholder: string;
	search: string;
	query: string;
	setSearch: (v: string) => void;
	module: SearchModule;
	records: any;
	activeTab: string;
	setActiveTab: (v: string) => void;
	isKlikstoreModule?: boolean;
	onKlikNowProductSelect: (item: IKlikStoreMasterProduct) => void;
	handleClickItemResult: () => void;
	handleKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
	onClickSearch: () => void;
};

const HeaderSearchDrawer = (props: Props) => {
	const {
		isComponentVisible,
		placeholder,
		search,
		query,
		module,
		records,
		activeTab,
		isKlikstoreModule,
		setIsComponentVisible,
		setSearch,
		handleClickItemResult,
		handleKeyDown,
		onClickSearch,
	} = props;
	const searchRef = useRef<any>(null);
	const r = useRouter();

	const showResults = query?.length >= SEARCH_MIN_CHAR.FOR_INFO;

	useEffect(() => {
		if (isComponentVisible && !!searchRef) searchRef?.current?.focus();
	}, [isComponentVisible]);

	useEffect(() => {
		if (r.query.keyword) {
			setSearch(r.query.keyword as string);
		}
	}, []);

	const handleChangeSearch = (e: any) => {
		const value = e.target.value;
		props.setSearch(value);
	};

	const handleBackButton = () => {
		props.setIsComponentVisible(false);
		props.setSearch('');
	};

	const renderSearchResult = () => {
		return (
			<HeaderSearchResults
				isComponentVisible={isComponentVisible}
				query={query}
				module={module}
				records={records}
				activeTab={activeTab}
				setActiveTab={props.setActiveTab}
				handleClickItemResult={handleClickItemResult}
				setSearch={props.setSearch}
			/>
		);
	};

	const onEnterSearch = () => {
		if (isKlikstoreModule && search.length >= 3) {
			setIsComponentVisible(false);
			r.push(`/kalstore/search?keyword=${search}&page=1`);
			return;
		}
	};
	return (
		<div>
			<Drawer
				className={classes.drawer}
				placement="top"
				height={'100%'}
				closable={false}
				onClose={() => props.setIsComponentVisible(false)}
				visible={isComponentVisible}
			>
				<Row
					gutter={[8, 8]}
					justify="space-between"
					align="middle"
					wrap={false}
					className={classes.header}
				>
					<Col className="d-flex align-items-center">
						<Button className={classes.back} type="text" onClick={handleBackButton}>
							<ImageC
								alt="back-icon"
								src="/icons/arrow-left.svg"
								width={32}
								height={32}
								layout="responsive"
								objectFit="contain"
							/>
						</Button>
					</Col>
					<Col flex="auto">
						<Form autoComplete="off">
							<Input
								ref={searchRef}
								className={classes.search}
								name="search"
								placeholder={placeholder}
								onPressEnter={onEnterSearch}
								value={search}
								onChange={handleChangeSearch}
								onKeyDown={handleKeyDown}
							/>
						</Form>
					</Col>
					<Col className="d-flex">
						<ImageC
							alt="Icon Search"
							src="/icons/search.svg"
							height={24}
							width={24}
							onClick={onClickSearch}
						/>
					</Col>
				</Row>
				{showResults && renderSearchResult()}
			</Drawer>
		</div>
	);
};

export default HeaderSearchDrawer;
