/* eslint-disable max-len */
const defaultRevalidate = '3600';
const adHomepageLD =
	process.env.NEXT_PUBLIC_AD_HOMEPAGE_LEADERBOARD &&
	process.env.NEXT_PUBLIC_AD_HOMEPAGE_LEADERBOARD.split('~~');
const adHomepageMH =
	process.env.NEXT_PUBLIC_AD_HOMEPAGE_MOBILE_HALFBANNER &&
	process.env.NEXT_PUBLIC_AD_HOMEPAGE_MOBILE_HALFBANNER.split('~~');
const adHomepageHP =
	process.env.NEXT_PUBLIC_AD_HOMEPAGE_HALFPAGE &&
	process.env.NEXT_PUBLIC_AD_HOMEPAGE_HALFPAGE.split('~~');
const adHomepageMR =
	process.env.NEXT_PUBLIC_AD_HOMEPAGE_MEDIUMREC &&
	process.env.NEXT_PUBLIC_AD_HOMEPAGE_MEDIUMREC.split('~~');
const adCtgArticleLLD =
	process.env.NEXT_PUBLIC_AD_CTG_ARTICLE_LLEADERBOARD &&
	process.env.NEXT_PUBLIC_AD_CTG_ARTICLE_LLEADERBOARD.split('~~');
const adCtgArticleHP =
	process.env.NEXT_PUBLIC_AD_CTG_ARTICLE_HALFPAGE &&
	process.env.NEXT_PUBLIC_AD_CTG_ARTICLE_HALFPAGE.split('~~');
const adCtgArticleMR =
	process.env.NEXT_PUBLIC_AD_CTG_ARTICLE_MEDIUMREC &&
	process.env.NEXT_PUBLIC_AD_CTG_ARTICLE_MEDIUMREC.split('~~');
const adDtlArticleLLD =
	process.env.NEXT_PUBLIC_AD_DTL_ARTICLE_LLEADERBOARD &&
	process.env.NEXT_PUBLIC_AD_DTL_ARTICLE_LLEADERBOARD.split('~~');
const adDtlArticleLD =
	process.env.NEXT_PUBLIC_AD_DTL_ARTICLE_LEADERBOARD &&
	process.env.NEXT_PUBLIC_AD_DTL_ARTICLE_LEADERBOARD.split('~~');
const adDtlArticleHP =
	process.env.NEXT_PUBLIC_AD_DTL_ARTICLE_HALFPAGE &&
	process.env.NEXT_PUBLIC_AD_DTL_ARTICLE_HALFPAGE.split('~~');
const adDtlArticleMR =
	process.env.NEXT_PUBLIC_AD_DTL_ARTICLE_MEDIUMREC &&
	process.env.NEXT_PUBLIC_AD_DTL_ARTICLE_MEDIUMREC.split('~~');
const adCtgArticleMB =
	process.env.NEXT_PUBLIC_AD_CTG_ARTICLE_MOBILE_BANNER &&
	process.env.NEXT_PUBLIC_AD_CTG_ARTICLE_MOBILE_BANNER.split('~~');
const adCtgArticleMH =
	process.env.NEXT_PUBLIC_AD_CTG_ARTICLE_MOBILE_HALFBANNER &&
	process.env.NEXT_PUBLIC_AD_CTG_ARTICLE_MOBILE_HALFBANNER.split('~~');
const adDtlArticleMB =
	process.env.NEXT_PUBLIC_AD_DTL_ARTICLE_MOBILE_BANNER &&
	process.env.NEXT_PUBLIC_AD_DTL_ARTICLE_MOBILE_BANNER.split('~~');
const adDtlArticleMH =
	process.env.NEXT_PUBLIC_AD_DTL_ARTICLE_MOBILE_HALFBANNER &&
	process.env.NEXT_PUBLIC_AD_DTL_ARTICLE_MOBILE_HALFBANNER.split('~~');
const adDtlArticleMM =
	process.env.NEXT_PUBLIC_AD_DTL_ARTICLE_MOBILE_MEDIUMREC &&
	process.env.NEXT_PUBLIC_AD_DTL_ARTICLE_MOBILE_MEDIUMREC.split('~~');

const global = {
	AUTH: {
		LOGIN: {
			label: 'login',
			phone: 'loginPhone',
			pin: 'loginPin',
		},
		REGISTER: {
			label: 'register',
			phone: 'registerPhone',
			otp: 'registerOtp',
			pin: 'registerPin',
			pinConfirm: 'registerPinConfirm',
			user: 'registerUser',
		},
		FORGOT: {
			label: 'forgot',
			phone: 'forgotPinPhone',
			otp: 'forgotPinOtp',
			pin: 'forgotPin',
			pinConfirm: 'forgotPinConfirm',
		},
		CHANGE_PIN: {
			label: 'change',
			oldPin: 'changePinOld',
			pin: 'changePin',
			pinConfirm: 'changePinConfirm',
		},
		SET_PIN: {
			label: 'set_pin',
		},
	},
	PROFILE: {
		FAMILY: {
			label: 'family',
			relationship: 'familyRelationship',
			registered: 'familyRegistered',
			unregistered: 'familyUnregistered',
			added: 'familyAdded',
		},
		ADDRESS_LIST: {
			label: 'addressList',
			confirmDelete: 'addressListConfirmDelete',
			successDelete: 'addressListSuccessDelete',
			pinPointAdd: 'addressListPinPointAdd',
			formAdd: 'addressListFormAdd',
			confirmAdd: 'addressListConfirmAdd',
			successAdd: 'addressListSuccessAdd',
			formEdit: 'addressListFormEdit',
			pinPointEdit: 'addressListPinPointEdit',
			confirmEdit: 'addressListConfirmEdit',
			successEdit: 'addressListSuccessEdit',
		},
		TRANSACTION: {
			SERVICE: {
				klikstore: 'klikstore',
				consultationChat: 'Konsultasi Chat',
				consultationVideo: 'Konsultasi Video Call',
				consultationFace: 'Konsultasi Tatap Muka',
				reservationDoctor: 'Janji Dokter',
				reservationService: 'Janji Layanan',
				prescriptionRedeem: 'Tebus Resep',
				prescriptionUpload: 'Upload Resep',
				healthyShopping: 'KALStore',
			},
			SCHEDULE: {
				chat: 'Live',
			},
		},
	},
	HOSPITAL: {
		DEFAULT: {
			id: process.env.DEFAULT_HOSPITAL_ID,
		},
	},
	DOCTOR: {
		SERVICES: {
			chat: 'chat',
			videoCall: 'videocall',
			janjiMedis: 'janji-medis',
			tatapMuka: 'face-to-face',
		},
	},
	PROMO: {
		MODULES: {
			chat: 'chat',
			videoCall: 'videocall',
			prescription: 'prescription',
			tatapMuka: 'tatap_muka_appointment',
			doctor: 'doctor_appointment',
			service: 'service_appointment',
			healthyShopping: 'belanjasehat',
		},
	},
	NOTIFICATION: {
		INFO: {
			prescription: 'Prescription',
			MembershipReminderExpired: 'MembershipReminderExpired',
			MembershipBirthday: 'MembershipBirthday',
		},
	},
	MENU: {
		PROFILE: [
			{
				name: 'Profil Saya',
				second_name: 'Profil Anggota',
				icon: '/icons/user.svg',
				link: '/profil',
				description: '',
				divider: false,
				parent: true,
				child: true,
			},
			/*{
				name: 'Asuransi Saya',
				second_name: 'Asuransi Saya',
				icon: '/icons/protection.svg',
				link: '/profil/klik-proteksi',
				description: '',
				divider: false,
				parent: true,
				child: false,
			},
			{
				name: 'Riwayat Kesehatan',
				second_name: 'Riwayat Kesehatan',
				icon: '/icons/medical-file.svg',
				link: '/profil/riwayat-kesehatan',
				description: '',
				divider: false,
				parent: true,
				child: true,
			},*/
			{
				name: 'Daftar Alamat',
				second_name: 'Daftar Alamat',
				icon: '/icons/map-location.svg',
				link: '/profil/daftar-alamat',
				description: '',
				divider: false,
				parent: true,
				child: false,
			},
			/*{
				name: 'Dokter Favorit',
				second_name: 'Dokter Favorit',
				icon: '/icons/favorite-doctor.svg',
				link: '/profil/dokter-favorit',
				description: '',
				divider: false,
				parent: true,
				child: false,
			},*/
			{
				name: 'Topik Saya',
				second_name: 'Topik Saya',
				icon: '/icons/my-topic.svg',
				link: '/profil/topik-saya',
				description: '',
				divider: false,
				parent: true,
				child: false,
			},
			/*
			{
				name: 'Monitor Kesehatan',
				second_name: 'Monitor Kesehatan',
				icon: '/icons/watch.svg',
				link: '/profil/monitor-kesehatan',
				description: '',
				divider: false,
				parent: true,
				child: false,
			},
			{
				name: 'Menunggu Pembayaran',
				second_name: 'Menunggu Pembayaran',
				icon: '/icons/waiting-payment.svg',
				link: '/profil/menunggu-pembayaran',
				description: '',
				divider: false,
				parent: true,
				child: false,
			},
			{
				name: 'Daftar Transaksi',
				second_name: 'Daftar Transaksi',
				icon: '/icons/transaction-list.svg',
				link: '/profil/daftar-transaksi',
				description: '',
				divider: false,
				parent: true,
				child: false,
			},
			{
				name: 'Wallet',
				second_name: 'Wallet',
				icon: '/icons/wallet.svg',
				link: '/profil/wallet',
				description: '',
				divider: true,
				parent: true,
				child: false,
			},
			{
				name: 'Undang Teman',
				second_name: 'Undang Teman',
				icon: '/icons/invite-friends.svg',
				link: '/profil/undang-teman',
				description: '',
				divider: false,
				parent: true,
				child: false,
			},
			{
				name: 'Sehat Squad',
				second_name: 'Sehat Squad',
				icon: '/icons/sehat-squad.svg',
				link: '/profil/sehat-squad',
				description: '',
				divider: false,
				parent: true,
				child: false,
			},
			{
				name: 'KlikPoin',
				second_name: 'KlikPoin',
				icon: '/icons/klikpoin.svg',
				link: '/profil/klikpoin',
				description: '',
				divider: false,
				parent: true,
				child: false,
			},
			*/
			{
				name: 'Keluar',
				second_name: 'Keluar',
				icon: '/icons/logout.svg',
				link: '/logout',
				description: '',
				divider: false,
				parent: true,
				child: false,
			},
		],
		DROPDOWN: {
			DOCTOR_SCHEDULE: [
				{
					value: 'chat',
					icon: '/icons/chat-2.svg',
				},
				{
					value: 'videocall',
					icon: '/icons/video-2.svg',
				},
				{
					value: 'janji-medis',
					icon: '/icons/face-to-face-2.svg',
				},
			],
			EDIT_PROFILE: [
				{
					name: 'Ubah Profil',
					icon: '/icons/pencil.svg',
					link: '/#edit',
					divider: false,
					parent: true,
					child: false,
				},
				{
					name: 'Ganti PIN',
					icon: '/icons/change-pin.svg',
					link: '/#change-pin',
					divider: false,
					parent: true,
					child: false,
				},
				{
					name: 'Hapus',
					icon: '/icons/delete.svg',
					link: '/#delete',
					divider: false,
					parent: false,
					child: true,
				},
			],
			ADD_ADDRESS: [
				{
					name: 'Rumah',
					icon: '/icons/home.svg',
				},
				{
					name: 'Apartemen',
					icon: '/icons/apartment.svg',
				},
				{
					name: 'Kantor',
					icon: '/icons/apartment.svg',
				},
				{
					name: 'Kos',
					icon: '/icons/apartment.svg',
				},
			],
			EDIT_ADDRESS: [
				{
					name: 'Jadikan Alamat Utama',
					icon: '/icons/home-bold.svg',
					link: '/#primary-address',
					primary: false,
					secondary: true,
				},
				{
					name: 'Ubah Alamat',
					icon: '/icons/pencil.svg',
					link: '/#edit-address',
					primary: true,
					secondary: true,
				},
				{
					name: 'Hapus Alamat',
					icon: '/icons/delete.svg',
					link: '/#delete-address',
					primary: false,
					secondary: true,
				},
			],
			EDIT_ADDRESS_NEW: [
				{
					name: 'Jadikan Alamat Utama',
					icon: '/icons/home-bold.svg',
					link: '/#primary-address',
					primary: false,
					secondary: true,
				},
				{
					name: 'Hapus Alamat',
					icon: '/icons/delete.svg',
					link: '/#delete-address',
					primary: false,
					secondary: true,
				},
			],
			SETTING_WAITING_PAYMENT: [
				// {
				// name: 'Batalkan Pesanan',
				// icon: '/icons/empty.svg',
				// link: '/#cancel',
				// },
				{
					name: 'Bantuan',
					icon: '/icons/empty.svg',
					link: '/#help',
				},
			],
			EDIT_TRANSACTIONS: [
				{
					name: 'Bantuan',
					icon: '/icons/empty.svg',
					link: '/#help',
				},
			],
			SETTING_CHAT_ROOM: [
				{
					name: 'Laporkan Masalah',
					icon: '/icons/empty.svg',
					link: '/#report',
				},
				{
					name: 'Akhiri Chat',
					icon: '/icons/empty.svg',
					link: '/#end-chat',
				},
			],
			SETTING_CHAT_ROOM_MP: [
				{
					name: 'Tutup Chat',
					icon: '/icons/empty.svg',
					link: '/#end-chat',
				},
			],
			SETTING_CHAT_ROOM_INPUT: [
				{
					name: 'Omron',
					icon: '/icons/omron/omron.svg',
					link: '/#omron',
				},
				{
					name: 'Dokumen',
					icon: '/icons/chat/note.svg',
					link: '/#document',
				},
				/* {
					name: 'Kamera',
					icon: '/icons/camera-gray.svg',
					link: '/#camera',
				}, */
				{
					name: 'Gallery',
					icon: '/icons/chat/gallery.svg',
					link: '/#gallery',
				},
			],
			SETTING_CHAT_ROOM_MP_INPUT: [
				{
					name: 'Produk',
					icon: '/icons/chat/belanja-sehat/produk.svg',
					link: '/#product',
				},
				{
					name: 'Transaksi',
					icon: '/icons/chat/belanja-sehat/transaksi.svg',
					link: '/#transaction',
				},
				{
					name: 'Galeri',
					icon: '/icons/chat/belanja-sehat/galeri.svg',
					link: '/#gallery',
				},
			],
			SETTING_CONSULTATION_VIDEO: [
				{
					name: 'Batalkan Pesanan',
					icon: '/icons/medical-file-cancel.svg',
					link: '/#cancel',
					primary: false,
					secondary: true,
				},
				{
					name: 'Bantuan',
					icon: '/icons/help.svg',
					link: '/#help',
					primary: true,
					secondary: true,
				},
				/* {
					name: 'Hapus',
					icon: '/icons/delete.svg',
					link: '/#delete',
					primary: true,
					secondary: false,
				}, */
			],
			SETTING_CONSULTATION_MEET: [
				/* {
					name: 'Batalkan Pesanan',
					icon: '/icons/medical-file-cancel.svg',
					link: '/#cancel',
					primary: false,
					secondary: true,
				}, */
				{
					name: 'Bantuan',
					icon: '/icons/help.svg',
					link: '/#help',
					primary: true,
					secondary: true,
				},
				/* {
					name: 'Hapus',
					icon: '/icons/delete.svg',
					link: '/#delete',
					primary: true,
					secondary: false,
				}, */
			],
		},
	},
	TABS: {
		SEARCH: [
			{
				type: 'askDoctor',
				name: 'Dokter',
			},
			{
				type: 'askDoctor',
				name: 'Spesialisasi',
			},
			{
				type: 'medicalAppointment',
				name: 'Dokter',
			},
			{
				type: 'medicalAppointment',
				name: 'Faskes',
			},
			{
				type: 'medicalAppointment',
				name: 'Layanan',
			},
		],
	},
	OPTION: {
		SEARCH: {
			LOCATION: [
				{
					uid: '-1',
					name: 'Gunakan Lokasi Saya',
					type: '',
					provinsi_uid: '0',
					sort: 0,
				},
				{
					uid: '-2',
					name: 'Semua Lokasi',
					type: '',
					provinsi_uid: '0',
					sort: 0,
				},
			],
		},
	},
	STATUS: {
		KLIKSTORE: {
			TRANSACTION: {
				pending: {
					value: 3,
					label: 'Sudah Dibayar',
					textColor: '#0075C9',
					bgColor: '#EDF5FB',
				},
				proceed: {
					value: 4,
					label: 'Sedang disiapkan',
					textColor: '#0075C9',
					bgColor: '#EDF5FB',
				},
				shipped: {
					value: 5,
					label: 'Sedang dikirim',
					textColor: '#0075C9',
					bgColor: '#EDF5FB',
				},
				delivered: {
					value: 7,
					label: 'Telah diterima',
					textColor: '#0075C9',
					bgColor: '#EDF5FB',
				},
				confirmed: {
					value: 8,
					label: 'Selesai',
					textColor: '#00A376',
					bgColor: '#EDF9F5',
				},
				refund: {
					value: 11,
					label: 'Dikembalikan',
					textColor: '#343A40',
					bgColor: '#F3F4F5',
				},
			},
		},
		TRANSACTION: {
			refund: 'Pengembalian Dana',
			settlement: 'Pembayaran Berhasil',
			waiting: 'Menunggu Pembayaran',
			failed: 'Pembayaran Gagal',
			expire: 'Pembayaran Gagal',
			cancelled: 'Pembayaran Gagal',
			shipping: 'Dalam Pengiriman',
			processing: 'Diproses Penjual',
			done: 'Selesai',
		},
		TRANSACTIONS: {
			CART: {
				value: 'cart',
				label: 'cart',
			},
			PENDING: {
				value: 'pending',
				label: 'pending',
			},
			WAITING: {
				value: 'waiting',
				label: 'waiting',
			},
			PAID: {
				value: 'paid',
				label: 'paid',
			},
			PROCEED: {
				value: 'proceed',
				label: 'proceed',
			},
			UNCONFIRMED: {
				value: 'unconfirmed',
				label: 'unconfirmed',
			},
			CONFIRMED: {
				value: 'confirmed',
				label: 'confirmed',
			},
			SHIPPED: {
				value: 'shipped',
				label: 'shipped',
			},
			DELIVERED: {
				value: 'delivered',
				label: 'delivered',
			},
			STARTING: {
				value: 'starting',
				label: 'starting',
			},
			ONGOING: {
				value: 'ongoing',
				label: 'ongoing',
			},
			ENDED: {
				value: 'ended',
				label: 'ended',
			},
			EXPIRED: {
				value: 'expired',
				label: 'expired',
			},
			REFUND: {
				value: 'refund',
				label: 'refund',
			},
			CANCEL_BY_THIRD_PARTY: {
				value: 'cancel_by_third_party',
				label: 'cancel_by_third_party',
			},
			CANCEL_BY_SYSTEM: {
				value: 'cancel_by_system',
				label: 'cancel_by_system',
			},
			CANCEL_BY_SELLER: {
				value: 'cancel_by_seller',
				label: 'cancel_by_seller',
			},
			CANCEL_BY_DOCTOR: {
				value: 'cancel_by_doctor',
				label: 'cancel_by_doctor',
			},
			CANCEL_BY_BUYER: {
				value: 'cancel_by_buyer',
				label: 'cancel_by_buyer',
			},
			CANCEL_BY_OPERATIONAL: {
				value: 'cancel_by_operational',
				label: 'cancel_by_operational',
			},
		},
		TRANSACTION_KEY: {
			refund: 'refund',
			settlement: 'settlement',
			waiting: 'waiting',
			failed: 'failed',
			expire: 'expire',
			expired: 'expired',
			cancelled: 'cancelled',
		},
	},
	FILE: {
		TYPE: {
			IMAGE_PDF_DOCS: [
				'image/jpeg',
				'image/png',
				'application/pdf',
				'application/msword',
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			],
		},
	},
	MAP: {
		KEY: process.env.NEXT_PUBLIC_GCP_API_KEY,
		LANGUAGE: 'ID',
		REGION: 'ID',
		TYPES: {
			establishment: 'establishment',
			subdistrict: 'administrative_area_level_4',
			district: 'administrative_area_level_3',
			city: 'administrative_area_level_2',
			province: 'administrative_area_level_1',
			postal_code: 'postal_code',
		},
		DATA: {
			defaultPosition: {
				lat: -6.180591913806673,
				lng: 106.8278846363983,
			},
		},
	},
	AD: {
		HOMEPAGE_LEADERBOARD_UNIT: adHomepageLD && adHomepageLD[0],
		HOMEPAGE_LEADERBOARD_SIZE: adHomepageLD ? JSON.parse(adHomepageLD[1]) : [],
		HOMEPAGE_MOBILE_HALFBANNER_UNIT: adHomepageMH && adHomepageMH[0],
		HOMEPAGE_MOBILE_HALFBANNER_SIZE: adHomepageMH ? JSON.parse(adHomepageMH[1]) : [],
		HOMEPAGE_HALFPAGE_UNIT: adHomepageHP && adHomepageHP[0],
		HOMEPAGE_HALFPAGE_SIZE: adHomepageHP ? JSON.parse(adHomepageHP[1]) : [],
		HOMEPAGE_MEDIUMREC_UNIT: adHomepageMR && adHomepageMR[0],
		HOMEPAGE_MEDIUMREC_SIZE: adHomepageMR ? JSON.parse(adHomepageMR[1]) : [],
		CTG_ARTICLE_LLEADERBOARD_UNIT: adCtgArticleLLD && adCtgArticleLLD[0],
		CTG_ARTICLE_LLEADERBOARD_SIZE: adCtgArticleLLD ? JSON.parse(adCtgArticleLLD[1]) : [],
		CTG_ARTICLE_HALFPAGE_UNIT: adCtgArticleHP && adCtgArticleHP[0],
		CTG_ARTICLE_HALFPAGE_SIZE: adCtgArticleHP ? JSON.parse(adCtgArticleHP[1]) : [],
		CTG_ARTICLE_MEDIUMREC_UNIT: adCtgArticleMR && adCtgArticleMR[0],
		CTG_ARTICLE_MEDIUMREC_SIZE: adCtgArticleMR ? JSON.parse(adCtgArticleMR[1]) : [],
		DTL_ARTICLE_LLEADERBOARD_UNIT: adDtlArticleLLD && adDtlArticleLLD[0],
		DTL_ARTICLE_LLEADERBOARD_SIZE: adDtlArticleLLD ? JSON.parse(adDtlArticleLLD[1]) : [],
		DTL_ARTICLE_LEADERBOARD_UNIT: adDtlArticleLD && adDtlArticleLD[0],
		DTL_ARTICLE_LEADERBOARD_SIZE: adDtlArticleLD ? JSON.parse(adDtlArticleLD[1]) : [],
		DTL_ARTICLE_HALFPAGE_UNIT: adDtlArticleHP && adDtlArticleHP[0],
		DTL_ARTICLE_HALFPAGE_SIZE: adDtlArticleHP ? JSON.parse(adDtlArticleHP[1]) : [],
		DTL_ARTICLE_MEDIUMREC_UNIT: adDtlArticleMR && adDtlArticleMR[0],
		DTL_ARTICLE_MEDIUMREC_SIZE: adDtlArticleMR ? JSON.parse(adDtlArticleMR[1]) : [],
		CTG_ARTICLE_MOBILE_BANNER_UNIT: adCtgArticleMB && adCtgArticleMB[0],
		CTG_ARTICLE_MOBILE_BANNER_SIZE: adCtgArticleMB ? JSON.parse(adCtgArticleMB[1]) : [],
		CTG_ARTICLE_MOBILE_HALFBANNER_UNIT: adCtgArticleMH && adCtgArticleMH[0],
		CTG_ARTICLE_MOBILE_HALFBANNER_SIZE: adCtgArticleMH ? JSON.parse(adCtgArticleMH[1]) : [],
		DTL_ARTICLE_MOBILE_BANNER_UNIT: adDtlArticleMB && adDtlArticleMB[0],
		DTL_ARTICLE_MOBILE_BANNER_SIZE: adDtlArticleMB ? JSON.parse(adDtlArticleMB[1]) : [],
		DTL_ARTICLE_MOBILE_HALFBANNER_UNIT: adDtlArticleMH && adDtlArticleMH[0],
		DTL_ARTICLE_MOBILE_HALFBANNER_SIZE: adDtlArticleMH ? JSON.parse(adDtlArticleMH[1]) : [],
		DTL_ARTICLE_MOBILE_MEDIUMREC_UNIT: adDtlArticleMM && adDtlArticleMM[0],
		DTL_ARTICLE_MOBILE_MEDIUMREC_SIZE: adDtlArticleMM ? JSON.parse(adDtlArticleMM[1]) : [],
	},
	QISCUS: {
		CHAT_ROOM: {
			TYPE: {
				roomCreated: 'room_created',
				roomEnded: 'room_ended',
				chatRate: 'chat_rate',
				contentHtml: 'content_html',
			},
			STATUS: {
				sending: 'sending',
				pending: 'pending',
				delivered: 'delivered',
				sent: 'sent',
				read: 'read',
				failed: 'failed',
			},
		},
	},
	MARKETPLACE: {
		SHOPEE:
			'https://shopee.co.id/klikdokterofficial?utm_campaign=s47410475_ss_id_webs_redirectbelanjasehat_bp&utm_source=website&utm_medium=seller&utm_content=redirectbelanjasehat',
		TOKOPEDIA:
			'https://tokopedia.link/g3y1XhJ6brb?utm_source=website&utm_medium=brand&utm_campaign=klikdokter_article_270622_270822',
		KALCARE: 'https://www.kalcare.com',
	},
	ERRORPAGE: {
		404: {
			imageUrl: '/images/vectors/404.svg',
			title: 'Halaman Tidak Ditemukan',
			description:
				'Wah halaman yang kamu cari tidak ditemukan nih! balik ke <br />halaman sebelumnya aja ya.',
		},
		500: {
			imageUrl: '/images/vectors/maintenance.svg',
			title: 'Terjadi Kesalahan',
			description:
				'Sepertinya ada kesalahan dari sistem KlikDokter. Kamu bisa coba <br />beberapa saat lagi ya.',
		},
	},
	WEB_ENV: process.env.NEXT_PUBLIC_WEB_ENV,
	REVALIDATE_EXP: parseInt((process.env.REVALIDATE_EXP as string) || defaultRevalidate),
	REVALIDATE_EXP_TD: parseInt((process.env.REVALIDATE_EXP_TD as string) || defaultRevalidate),
	REVALIDATE_EXP_JM: parseInt((process.env.REVALIDATE_EXP_JM as string) || defaultRevalidate),
	REVALIDATE_EXP_ARTICLE: parseInt(
		(process.env.REVALIDATE_EXP_ARTICLE as string) || defaultRevalidate,
	),
	WEARABLE_DEVICE: {
		OMRON: {
			CODE: 'omron',
			URL_CONNECT: `${process.env.OMRON_URL}?response_type=code&client_id=${process.env.OMRON_CLIENT_ID}&redirect_uri=${process.env.OMRON_REDIRECT_URI}&state=${process.env.OMRON_STATE}&scope=openid scope_for_b2b_groups&master_app_key=${process.env.OMRON_MASTER_API_KEY}&result_url=${process.env.NEXT_PUBLIC_WEB_URL}/profil/monitor-kesehatan&group_name=omronklikdokter&group_id=${process.env.OMRON_GROUP_ID}`,
		},
	},
	DEVICE: 'web',
	STEM_CELL: {
		URLS: {
			REGISTER: process.env.STEM_CELL_REGISTER_URL,
		},
	},
};

export default global;
