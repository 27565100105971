import { useEffect } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

import { trackInsiderObject } from 'src/utils/analytics/insider';
import AuthStorage from 'src/utils/auth-storage';

const InsiderTrackingUser = () => {
	const auth = useSelector((state: RootStateOrAny) => state?.auth);
	const userDataProfile = auth.user?.data?.record || auth?.userData?.data?.record;

	useEffect(() => {
		const userDataJwt = AuthStorage.tokenDecode || {};

		const loginStatusAttribute = {
			login: AuthStorage.loggedIn,
			logout: !AuthStorage.loggedIn,
		};

		const getProfileAttribute = () => ({
			gender: userDataProfile?.gender,
			surname: userDataProfile?.last_name,
			age: userDataProfile?.age,
			birthday: userDataProfile?.dob,
		});

		const getUserObject = () => ({
			uuid: userDataJwt.id && String(userDataJwt.id),
			email: userDataJwt.email,
			name: userDataJwt.full_name,
			phone_number: userDataJwt.phone && `+${userDataJwt.phone}`,
			language: 'en-US',
			pushOptin: typeof Notification !== 'undefined' && Notification.permission === 'granted',
			email_optin: true,
			gdpr_optin: true,
			whatsapp_optin: true,
			...loginStatusAttribute,
			...(userDataProfile && getProfileAttribute()),
		});

		if (!AuthStorage.loggedIn || userDataProfile) {
			trackInsiderObject(
				'user',
				AuthStorage.loggedIn,
			)(AuthStorage.loggedIn ? getUserObject() : loginStatusAttribute);
		}
	}, [userDataProfile, AuthStorage.loggedIn]);

	return null;
};

export default InsiderTrackingUser;
