import { useQuery } from 'react-query';

import { PUBLISHING_KD } from 'src/constants/services';
import { BannerAd } from 'src/interface';
import { ResponseData } from 'src/interface/Commons';
import { fetchApiV3Publishing } from 'src/utils/fetch-api';

export const bannerAdKey = 'bannerAds';

export const useBannerAd = () => {
	const { data, ...restAttributes } = useQuery(
		bannerAdKey,
		(): Promise<ResponseData<{ record: BannerAd }, any>> =>
			fetchApiV3Publishing({
				url: `/${PUBLISHING_KD.service}/${PUBLISHING_KD.model.ads}`,
				options: { method: 'GET' },
			}),
		{
			retry: true,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	);

	return {
		data: data?.data?.record,
		meta: data?.meta,
		...restAttributes,
	};
};
