/* eslint-disable max-len */
import { SearchModules } from 'src/interface';
import { highlightMatchingWord } from 'src/utils/func';

export const SEARCH_MODULES: SearchModules = {
	DEFAULT_GLOBAL: {
		name: 'default-global',
		placeholder: 'Cari di KlikDokter',
		tabs: [
			{
				name: 'Dokter',
				getData: (item: any) => item?.data?.data?.records,
				getUrl: (item: any) => ({
					pathname: `/dokter/${item?.slug}`,
				}),
				getLabel: (item: any) => ({
					type: 'simple-search',
					content: {
						title: highlightMatchingWord(item?.display_name, item?.query),
					},
				}),
			},
		],
	},
	ASK_DOCTOR: {
		name: 'ask-doctor',
		placeholder: 'Cari dokter, spesialiss',
		tabs: [
			{
				name: 'Dokter',
				getData: (records: any) => records?.data?.data?.record?.doctors,
				getUrl: (record: any) => ({
					pathname: '/tanya-dokter/list',
					query: { q: record?.display_name, suggestion: 'doctor' },
				}),
				getLabel: (record: any) => ({
					type: 'simple-search',
					content: {
						title: highlightMatchingWord(record?.display_name, record?.query),
					},
				}),
			},
			{
				name: 'Spesialisasi',
				getData: (records: any) => records?.data?.data?.record?.specialities,
				getUrl: (record: any) => ({
					pathname: '/tanya-dokter/list',
					query: { q: record?.speciality, suggestion: 'speciality', uid: record?.uid },
				}),
				getLabel: (record: any) => ({
					type: 'simple-search',
					content: {
						title: highlightMatchingWord(record?.speciality, record?.query),
					},
				}),
			},
		],
	},
	MEET_DOCTOR: {
		name: 'meet-doctor',
		placeholder: 'Cari Dokter, faskes, spesialisasi',
		tabs: [
			{
				name: 'Dokter',
				getData: (records: any) => records?.data?.results?.[0]?.grouped_hits,
				getUrl: (record: any) => ({
					pathname: `/temu-dokter/dokter/${record?.hits?.[0]?.document?.slug}`,
				}),
				getLabel: (record: any) => ({
					type: 'simple-search',
					content: {
						title: record?.hits?.[0]?.highlights?.[0]?.value,
					},
				}),
			},
			{
				name: 'Faskes',
				getData: (record: any) => record?.data?.results?.[1]?.hits,
				getUrl: (record: any) => ({
					pathname: `/${record?.document?.type_code}/${record?.document?.slug}`,
				}),
				getLabel: (record: any) => ({
					type: 'simple-search',
					content: {
						title: record?.highlights?.[0]?.value,
					},
				}),
			},
			{
				name: 'Spesialisasi',
				getData: (records: any) => records?.data?.results?.[2]?.hits,
				getUrl: (record: any) => ({
					pathname: `/temu-dokter/${record?.document?.slug}`,
				}),
				getLabel: (record: any) => ({
					type: 'simple-search',
					content: {
						title: record?.highlights?.[0]?.value,
					},
				}),
			},
		],
	},
	HEALTH_SERVICE: {
		name: 'health-service',
		placeholder: 'Cari Layanan',
		tabs: [
			{
				name: 'Layanan',
				getData: (item: any) => item?.data?.results?.[0]?.hits,
				getUrl: ({ document: item }: any) => ({
					pathname: `/${item?.medical_facility_type_code}/${item?.medical_facility_slug}/${item?.slug}`,
				}),
				getLabel: (item: any) => ({
					type: 'detail-search',
					content: {
						title: item?.highlights?.[0]?.value,
						description: `${item?.document?.medical_facility_name} • ${item?.document?.medical_facility_city}`,
					},
				}),
			},
			{
				name: 'Faskes',
				getData: (item: any) => item?.data?.results?.[1]?.hits,
				getUrl: (item: any) => ({
					pathname: `/${item?.document?.type_code}/${item?.document?.slug}`,
				}),
				getLabel: (item: any) => ({
					type: 'simple-search',
					content: {
						title: item?.highlights?.[0]?.value,
					},
				}),
			},
		],
	},
	MEDICAL_FACILITY: {
		name: 'medical-facility',
		placeholder: 'Cari Layanan',
		tabs: [
			{
				name: 'Dokter',
				getData: (item: any) => item?.data?.results?.[0]?.grouped_hits,
				getUrl: (item: any) => ({
					pathname: `/temu-dokter/dokter/${item?.hits?.[0]?.document?.slug}`,
				}),
				getLabel: (item: any) => ({
					type: 'simple-search',
					content: {
						title: item?.hits?.[0]?.highlights?.[0]?.value,
					},
				}),
			},
			{
				name: 'Faskes',
				getData: (item: any) => item?.data?.results?.[1]?.hits,
				getUrl: (item: any) => ({
					pathname: `/${item?.document?.type_code}/${item?.document?.slug}`,
				}),
				getLabel: (item: any) => ({
					type: 'simple-search',
					content: {
						title: item?.highlights?.[0]?.value,
					},
				}),
			},
			{
				name: 'Layanan',
				getData: (item: any) => item?.data?.results?.[2]?.hits,
				getUrl: ({ document: item }: any) => ({
					pathname: `/${item?.medical_facility_type_code}/${item?.medical_facility_slug}/${item?.slug}`,
				}),
				getLabel: (item: any) => ({
					type: 'detail-search',
					content: {
						title: item?.highlights?.[0]?.value,
						description: `${item?.document?.medical_facility_name} • ${item?.document?.medical_facility_city}`,
					},
				}),
			},
		],
	},
	KLIKSTORE: {
		name: 'klikstore',
		placeholder: 'Cari di KALStore',
		tabs: [],
		tab: {
			name: 'KALStore',
			getData: (item: any, index?: number) => {
				if (index === 1) {
					return item?.data?.data?.records?.kliknow?.slice(0, 3) ?? [];
				} else if (index === 2) {
					return item?.data?.data?.records?.products?.slice(0, 3) ?? [];
				}
				return item?.data?.data?.records?.merchants?.slice(0, 3) ?? [];
			},
			getUrl: (item: any, index?: number) => {
				if (index === 2) {
					return `/kalstore/s/${item?.slug}`;
				}
				return `/kalstore/p/${item?.slug}`;
			},
			getLabel: (item: any) => item?.highlights?.[0]?.value,
		},
	},
	ARTICLE: {
		name: 'article',
		placeholder: 'Cari Artikel',
		tabs: [
			{
				name: 'Artikel',
				getData: (records: any) => records?.data?.data?.records,
				getUrl: (record: any) => ({
					pathname: record?.url,
				}),
				getLabel: (record: any) => ({
					type: 'simple-search',
					content: {
						title: highlightMatchingWord(record?.title, record?.query),
						category: record?.category_name,
						publishDate: record?.publish_date,
						image: record?.media_image_mobile,
					},
				}),
			},
		],
	},
};

export const SEARCH_MIN_CHAR = {
	FOR_INFO: 0,
	FOR_LIST: 3,
};
