import { useEffect, useState } from 'react';

export const useIsEmptyContentGoogleAd = () => {
	const [isEmptyContent, setIsEmptyContent] = useState<boolean | null>(null);
	let googletag: any;

	useEffect(() => {
		window.googletag = window.googletag || { cmd: [] };
		googletag = window.googletag;
		googletag.cmd.push(function () {
			googletag.pubads().addEventListener('slotRenderEnded', function (event: any) {
				setIsEmptyContent((prev) => (prev === false ? prev : event.isEmpty));
			});
		});
	}, []);

	return isEmptyContent;
};
