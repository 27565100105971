import { useQuery } from 'react-query';

import { DOCTOR } from 'src/constants/services';
import { fetchApiConsultation, fetchApiJM } from 'src/utils/fetch-api';

export const useSearchSuggestions = (
	search: string,
	search_type: string,
	enabled: boolean = true,
) => {
	return useQuery(
		['searchSuggestions', search],
		() => {
			if (search_type == 'medicalAppointment') {
				return fetchApiJM({
					url: `/${DOCTOR.model.searchSuggestions}`,
					payload: { search },
				});
			}
			return fetchApiConsultation({
				url: `/${DOCTOR.service}/${DOCTOR.model.searchSuggestions}`,
				payload: { search },
			});
		},
		{ enabled: !!search && enabled, refetchOnWindowFocus: false },
	);
};
