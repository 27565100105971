import { useQuery } from 'react-query';

import { ARTICLE_BY_GROUP, TYPESENSE } from 'src/constants/services';
import { UserLocation } from 'src/interface';
import { fetchApiMarketplace, fetchApiTypesense, fetchApiV3Publishing } from 'src/utils/fetch-api';

interface UseDefaultGlobalSearch {
	query: string;
	location: UserLocation;
	activeTab: string;
	enabled: boolean;
}

export const useDefaultGlobalSearch = (props: UseDefaultGlobalSearch) => {
	// const { lat, lng } = props.location;

	return useQuery(
		['useDefaultGlobalSearch', props],
		() => {
			return fetchApiV3Publishing({
				options: { method: 'POST' },
				url: `${ARTICLE_BY_GROUP.service}/${ARTICLE_BY_GROUP.model.globalSearch}`,
				payload: {
					title: props.query,
				},
			});
		},
		{
			enabled: !!props.query && props.query.length >= 3 && props.enabled,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);
};

interface UseMeetDoctorSearch {
	query: string;
	location: UserLocation;
	enabled: boolean;
}

export const useMeetDoctorSearch = (props: UseMeetDoctorSearch) => {
	return useQuery(
		['useMeetDoctorSearch', props],
		() => {
			return fetchApiTypesense({
				url: `/${TYPESENSE.service}`,
				options: { method: 'POST' },
				payload: {
					searches: [
						{
							exhaustive_search: true,
							prioritize_exact_match: true,
							q: props.query,
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilityDoctors,
							sort_by: `_text_match:desc,location(${props.location.lat},${props.location.lng}):asc`,
							group_by: 'master_uid',
							highlight_full_fields: 'name',
							highlight_start_tag: '<b>',
							highlight_end_tag: '</b>',
							per_page: 5,
						},
						{
							exhaustive_search: true,
							prioritize_exact_match: true,
							q: props.query,
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilities,
							sort_by: `_text_match:desc,location(${props.location.lat},${props.location.lng}):asc`,
							highlight_full_fields: 'name',
							highlight_start_tag: '<b>',
							highlight_end_tag: '</b>',
							per_page: 5,
						},
						{
							exhaustive_search: true,
							prioritize_exact_match: true,
							q: props.query,
							query_by: 'name',
							collection: TYPESENSE.model.categorySpecialities,
							sort_by: '_text_match:desc',
							highlight_start_tag: '<b>',
							highlight_end_tag: '</b>',
							highlight_full_fields: 'name',
							per_page: 5,
						},
					],
				},
			});
		},
		{
			enabled: !!props.query && props.query.length >= 3 && props.enabled,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);
};

interface UseHealthServiceSearch {
	query: string;
	location: UserLocation;
	enabled: boolean;
}

export const useHealthServiceSearch = (props: UseHealthServiceSearch) => {
	return useQuery(
		['useHealthServiceSearch', props],
		() => {
			return fetchApiTypesense({
				url: `/${TYPESENSE.service}`,
				options: { method: 'POST' },
				payload: {
					searches: [
						{
							exhaustive_search: true,
							prioritize_exact_match: true,
							q: props.query,
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilityServices,
							sort_by: `_text_match:desc,location(${props.location.lat},${props.location.lng}):asc`,
							highlight_full_fields: 'name',
							highlight_start_tag: '<b>',
							highlight_end_tag: '</b>',
							include_fields:
								'name,slug,medical_facility_type_code,medical_facility_slug,' +
								'medical_facility_name,medical_facility_city',
							per_page: 5,
						},
						{
							exhaustive_search: true,
							prioritize_exact_match: true,
							q: props.query,
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilities,
							sort_by: `_text_match:desc,location(${props.location.lat},${props.location.lng}):asc`,
							highlight_full_fields: 'name',
							highlight_start_tag: '<b>',
							highlight_end_tag: '</b>',
							include_fields: 'name,slug,type_code',
							per_page: 5,
						},
					],
				},
			});
		},
		{
			enabled: !!props.query && props.query.length >= 3 && props.enabled,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);
};

interface UseMedicalFacilitySearch {
	query: string;
	location: UserLocation;
	enabled: boolean;
}

export const useMedicalFacilitySearch = (props: UseMedicalFacilitySearch) => {
	return useQuery(
		['useMedicalFacilitySearch', props],
		() => {
			return fetchApiTypesense({
				url: `/${TYPESENSE.service}`,
				options: { method: 'POST' },
				payload: {
					searches: [
						{
							exhaustive_search: true,
							prioritize_exact_match: true,
							q: props.query,
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilityDoctors,
							sort_by: `_text_match:desc,location(${props.location.lat},${props.location.lng}):asc`,
							group_by: 'master_uid',
							highlight_full_fields: 'name',
							highlight_start_tag: '<b>',
							highlight_end_tag: '</b>',
							include_fields: 'name,slug',
							per_page: 5,
						},
						{
							exhaustive_search: true,
							prioritize_exact_match: true,
							q: props.query,
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilities,
							sort_by: `_text_match:desc,location(${props.location.lat},${props.location.lng}):asc`,
							highlight_full_fields: 'name',
							highlight_start_tag: '<b>',
							highlight_end_tag: '</b>',
							include_fields: 'name,slug,type_code',
							per_page: 5,
						},
						{
							exhaustive_search: true,
							prioritize_exact_match: true,
							q: props.query,
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilityServices,
							sort_by: `_text_match:desc,location(${props.location.lat},${props.location.lng}):asc`,
							highlight_full_fields: 'name',
							highlight_start_tag: '<b>',
							highlight_end_tag: '</b>',
							include_fields:
								'name,slug,medical_facility_type_code,medical_facility_slug,' +
								'medical_facility_name,medical_facility_city',
							per_page: 5,
						},
					],
				},
			});
		},
		{
			enabled: !!props.query && props.query.length >= 3 && props.enabled,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);
};

interface UseKlikstoreSearch {
	query: string;
	location?: UserLocation;
	enabled: boolean;
}

export const useKlikstoreMasterSearch = (props: UseKlikstoreSearch) => {
	return useQuery(
		['useKlikstoreMasterSearch', props],
		() => {
			return fetchApiMarketplace({
				url: '/product_autocomplete',
				options: { method: 'GET' },
				payload: {
					q: props.query,
				},
			});
		},
		{
			enabled: !!props.query && props.query.length >= 3 && props.enabled,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);
};
