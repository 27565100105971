import { Col, List, Row, Typography } from 'antd';

import ImageC from 'src/components/Image';
import { SearchModuleLabel } from 'src/interface';

import classes from './HeaderSearchListItem.module.less';

type Props = {
	label: SearchModuleLabel;
};

const HeaderSearchListItem = (props: Props) => {
	const { label } = props;
	const { type, content } = label;

	if (type === 'detail-search') {
		return (
			<List.Item className={classes['detail-search']}>
				<span
					className={classes.title}
					dangerouslySetInnerHTML={{ __html: content?.title || '' }}
				/>
				<Typography.Text className={classes.description}>{content?.description}</Typography.Text>
			</List.Item>
		);
	}

	return (
		<List.Item className={classes['simple-search']}>
			<Row wrap={false} align="middle" className="w-100">
				<Col flex="60px" className="mr-3 d-flex">
					<ImageC
						className="rounded-lg w-100"
						height="60"
						width="60"
						src={content?.image}
						objectFit="cover"
					/>
				</Col>
				<Col flex="auto">
					<div className="d-flex gapx-3">
						<div className="d-flex flex-column justify-content-center">
							<span
								className={classes.title}
								dangerouslySetInnerHTML={{ __html: content?.title || '' }}
							/>
							<div className="gapx-1 fs-12">
								<span className="text-primary fw-500">{content?.category}</span>
								<span>{content?.publishDate}</span>
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</List.Item>
	);
};

export default HeaderSearchListItem;
